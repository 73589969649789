<!-- 公共弹框 -->
<template>
  <section class="dialog-wrap" v-if="showMask" draggable>
    <div :id="dialogMove" class="dialog-container" :style="defaultSty">
      <div class="dialog-title">
        {{ title }}
        <i
          class="fa fa-times-circle dialog-right"
          @click="showMask = false"
        ></i>
      </div>
      <slot></slot>
      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script>
import { ref, computed, watch } from 'vue'

export default {
  name: 'dialogWrap',
  props: {
    isshow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '提示',
    },
    width: {
      required: true,
      default: '400px',
      type: String,
    },
    top: {
      default: '5%',
      type: String,
    },
    height: {
      type: String,
    },
  },
  emits: ['handleShow'],
  setup(props, ctx) {
    const dialogMove = ref('Dialog' + props.width)
    const showMask = ref(false)

    const defaultSty = computed(() => {
      return {
        width: props.width,
        height: props.height,
        top: props.top,
      }
    })
    watch(
      () => props.isshow,
      (val) => {
        showMask.value = val
      }
    )
    watch(
      () => showMask.value,
      (val) => {
        ctx.emit('handleShow', val)
      }
    )

    return {
      defaultSty,
      dialogMove,
      showMask,
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6;
  overflow-y: auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .dialog-container {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    // overflow: hidden;
    min-height: 100px;
    background: #ffffff;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    padding: 16px;
    border-radius: 16px;
    animation: action_translateY 0.5s forwards ease-out;
    @keyframes action_translateY {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .dialog-title {
      width: 100%;
      height: 40px;
      background: rgba(49, 102, 174, 0.1);
      border-radius: 8px;
      line-height: 40px;
      font-size: 18px;
      font-family: 'Source Han Sans CN-Medium';
      color: #000000;
      padding: 0 0 0 30px;
      // background: $digTitleMain;
      .dialog-right:hover {
        animation: rotate1 0.5s linear;
      }
      @keyframes rotate1 {
        from {
          transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -moz-transform: roate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
        }
        to {
          transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
      .dialog-right {
        float: right;
        line-height: 40px;
        margin-right: 17px;
        font-size: 20px;
        cursor: pointer;
        color: rgba(49, 102, 174, 0.2);
      }
    }
    .footer {
      text-align: center;
      width: 100%;
      height: 60px;
      // line-height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .el-button--small {
      width: 98px;
      height: 32px;
    }
  }
}
</style>
